import events from '@layers/web/constants/events.js'

/* eslint-disable */
function getVar(name) {
  let get_string = document.location.search
  let return_value = ''
  let name_index = null
  let space
  let end_of_value
  let value

  do {
    name_index = get_string.indexOf(name + '=');
    if (name_index != -1) {
      get_string = get_string.substr(name_index + name.length + 1,
        get_string.length - name_index);
      end_of_value = get_string.indexOf('&');
      if (end_of_value != -1) {
        value = get_string.substr(0, end_of_value);
      } else {
        value = get_string;
      }
      if (return_value == '' || value == '') {
        return_value += value;
      } else {
        return_value += ', ' + value;
      }
    }
  }
  while (name_index != -1);
  space = return_value.indexOf('+');
  while (space != -1) {
    return_value = return_value.substr(0, space) + ' ' +
      return_value.substr(space + 1, return_value.length);
    space = return_value.indexOf('+');
  }
  return (return_value);
}

function setCookie(name, value, expires, path, domain, secure, sameSite) {
  var today = new Date();
  today.setTime(today.getTime());
  if (expires) {
    expires = expires * 1000 * 60 * 60 * 24;
  }
  var expires_date = new Date(today.getTime() + (expires));
  document.cookie = name + '=' + escape(value) +
    ((expires) ? '; expires=' + expires_date.toGMTString() : '') +
    ((path) ? '; path=' + path : '') +
    ((domain) ? '; domain=' + domain : '') +
    ((secure) ? '; secure' : '') +
    ((sameSite) ? '; SameSite=' + sameSite : '');
}

function initTD () {
  if(getCookie('a')) {
    return
  }

  var mytduid = getVar('tduid');

  if (mytduid != '') {
    setCookie('TRADEDOUBLER', mytduid, 365, '/', null, true, 'None');
  }

  (function () {
    let a = document.getElementsByTagName('head')[0]
    let r = document.createElement('script')
    r.async = 1
    r.id = 'tdbl'
    r.src = '//wrap.tradedoubler.com/wrap?id=27023'
    a.appendChild(r);
  })()
}
/* eslint-enable  */

export default defineNuxtPlugin(() => {
  const { public: { locale } } = useRuntimeConfig()
  if (locale !== 'zz') {
    return
  }

  const { userConsentStatus } = useUserConsent()
  if (userConsentStatus.value.advertising) {
    initTD()
  }

  window.addEventListener(events.rbcsChange, (e) => {
    if (!e.detail.userConsentStatus.advertising) {
      return
    }

    initTD()
  })
})
