import { default as _91slug_931IlNwygK5DMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93SSjLMgNVFTMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93ZB8GzGwYuZMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91id_93Zk4KpfcVDpMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93crzZg4TVzxMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_93g9SpANGRPOMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91slug_93GV8mkbJuE6Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91tab_934nj51zas7fMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue?macro=true";
import { default as _91id_93zQIPGR7ZM7Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93y5dbJ7C2y5Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue?macro=true";
import { default as _91slug_93aloxVcSxgqMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91name_93XBMgIulVXEMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/charterpackagescampaign/[name].vue?macro=true";
import { default as _91ocr_93y96lnqamOXMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93mkDJp9Hkz0Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93QrhYGPCLjwMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93Ltsb2XJUORMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91jwt_93BsXXnSMVl1Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93BSgQGhVTQtMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as activitiesTnh9t0IWkmMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/activities.vue?macro=true";
import { default as indexJhVKDMkST5Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as byebyeieiEBRhRlGW9Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as charterlastminute74Q8kLYvq3Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/charterlastminute.vue?macro=true";
import { default as gruppresorCHbxVc4GufMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/charterpackagescampaign/gruppresor.vue?macro=true";
import { default as chartersearchrwJUDYM123Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue?macro=true";
import { default as complaint4v8NumOV4pMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as indexGUZUxlO2sAMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue?macro=true";
import { default as dinbokning_45aktuellfOqVftAuhtMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as faqrcbtipFyzSMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as indexyCEl2kHTT4Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue?macro=true";
import { default as _91id_93qA96tKvTyxMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as index0JL3psXRpLMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as indexLpZtz3qicAMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue?macro=true";
import { default as _91name_93cl65tx2b78Meta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexXXviwuYMlWMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexRJbknw0PHnMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/index.vue?macro=true";
import { default as dinbokningFJdRm2yTgEMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterJGgIxZrN1AMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlinePTqpf1ZEEYMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as packagessearchOyW0ClhqAWMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue?macro=true";
import { default as failedzA0Vk2SIVLMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexOD9w62e8seMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as searchwN7VtNfygUMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexFMIUHljj7HMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45doneXIiU6Imx9SMeta } from "/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "hotel-id",
    path: "/hotel/:id()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-id",
    path: "/destination/:id()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "charterpackagescampaign",
    path: "/campaign",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/charterpackagescampaign/[name].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93BSgQGhVTQtMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "activities",
    path: "/utflykter-och-aktiviteter",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/activities.vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "charterlastminute",
    path: "/sista-minuten-erbjudanden",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/charterlastminute.vue")
  },
  {
    name: "charterpackagescampaign-name",
    path: "/campaign/:name()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/charterpackagescampaign/[name].vue")
  },
  {
    name: "charterpackagescampaign-gruppresor",
    path: "/campaign/gruppresor",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/charterpackagescampaign/gruppresor.vue")
  },
  {
    name: "chartersearch",
    path: "/sok-charter",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/chartersearch.vue")
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaint4v8NumOV4pMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-id-tab",
    path: "/destination/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/destination/[id]/[tab].vue")
  },
  {
    name: "destination",
    path: "/destination",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/destination/index.vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "favourites",
    path: "/favourites",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/favourites/index.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotel-id-tab",
    path: "/hotel/:id()/:tab()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/hotel/[id]/[tab].vue")
  },
  {
    name: "hotel",
    path: "/hotel",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/hotel/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_93cl65tx2b78Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93GV8mkbJuE6Meta || {},
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_93g9SpANGRPOMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93crzZg4TVzxMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "packagessearch",
    path: "/sok-packages",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/applications/solresor-web/pages/packagessearch.vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_93ZB8GzGwYuZMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45doneXIiU6Imx9SMeta || {},
    component: () => import("/home/ubuntu/deployments/2024-11-14-1648/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]